import React, { Component } from "react";
import { Link } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import { Container, Row, Col } from "glamorous-grid";
import { FaMapMarker } from "react-icons/fa/";
import { FaEnvelope } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa/";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

const mediaQueries = {
  phone: "@media only screen and (max-width: 991px)",
};

const FooterDiv = g.footer({
  color: `white`,
  backgroundColor: `rgb(60, 77, 104)`,
  padding: `3em 0 2em`,
});

const Wrapper = g.div({
  maxWidth: `1200px`,
  margin: `0 auto`,
});

const H4 = g.h4({
  margin: `0 auto 1em`,
  fontWeight: `300`,
  fontSize: `1.5em`,
  letterSpacing: `0.1em`,
  [mediaQueries.phone]: {
    fontSize: `1.35em`,
    textAlign: `center`,
    margin: `.7em auto .4em`,
    letterSpacing: `0.07em`,
  },
});

const Address = g.div({ fontSize: `1em` });

const Ul = g.ul({
  listStyle: `none`,
  margin: `0 !important`,
});

const Li = g.li({
  fontSize: `.95em`,
  [mediaQueries.phone]: {
    textAlign: `center`,
  },
});

const A = g.a({
  color: `white`,
});

const Circle = g.div({
  display: `inline-block`,
  textAlign: `center`,
  width: `44px`,
  height: `44px`,
  margin: `.5em .8em .2em 0`,
  fontSize: `1.4em`,
  border: `2px solid white`,
  borderRadius: `50%`,
  position: `relative`,
  transition: `all 0.2s ease-in-out 0s`,
  ":hover": {
    backgroundColor: `white`,
    transition: `all 0.2s ease-in-out 0s`,
  },
  ":hover svg": {
    color: `#3c4d68`,
    transition: `all 0.2s ease-in-out 0s`,
  },
});

const cssFa = css({
  position: `absolute`,
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
  transition: `all 0.2s ease-in-out 0s`,
});

const Copyright = g.div({
  fontSize: `.7em`,
  opacity: `.9`,
});

const cssLink = css({
  color: "white",
  fontSize: `.9em`,
  textDecoration: `none`,
  borderBottom: `none`,
  transition: `all .3s`,
  padding: `0 0 3px 0`,
  margin: `0`,
  position: `relative`,
  "::before": {
    content: " ",
    position: `absolute`,
    width: `100%`,
    height: `1px`,
    bottom: `0`,
    left: `0`,
    backgroundColor: `#fff`,
    visibility: `invisible`,
    transform: `scaleX(0)`,
    transition: `all 0.3s ease-in-out 0s`,
  },
  ":hover": {
    transition: `all .3s`,
    border: `none`,
    background: `none`,
    padding: `0 0 3px 0`,
    margin: `0`,
    "::before": {
      visibility: `visible`,
      transform: `scaleX(1)`,
      transition: `all 0.3s ease-in-out 0s`,
    },
  },
});

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { language: "francais" };
  }

  render() {
    return (
      <FooterDiv>
        <Wrapper>
          <Container>
            <Row>
              <Col span={{ xs: 12 / 12, lg: 37 / 100 }}>
                <H4>Vous avez une question ? </H4>
                <Address>
                  Cabinet du Dr Cédric Kron
                  <br />
                  Chirurgie Esthétique, Plastique et Reconstructrice
                  <br />
                  Médecine Esthétique à Paris
                  <br />
                </Address>
                <Address>
                  <A href="tel:+33145628500" {...cssLink}>
                    <FaPhone
                      size={`17`}
                      css={{ marginRight: `6px` }}
                      style={{ verticalAlign: "text-bottom" }}
                    />
                    01 45 62 85 00
                  </A>
                  <br />
                  <Link to="/contactez-nous/" {...cssLink}>
                    <FaEnvelope
                      size={`17`}
                      css={{ marginRight: `6px` }}
                      style={{ verticalAlign: "text-bottom" }}
                    />
                    Envoyez-nous un message
                  </Link>
                  <br />
                  <a
                    href="https://goo.gl/maps/ZPEQycbj3W42"
                    {...cssLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaMapMarker
                      size={`17`}
                      css={{ marginRight: `6px` }}
                      style={{ verticalAlign: "text-bottom" }}
                    />
                    57 avenue de Villiers - 75017 Paris
                  </a>
                  <br />
                </Address>
                <A
                  href="https://www.facebook.com/DrCedricKron"
                  title="Page Facebook du Dr Cédric Kron à Paris"
                  target="_blank"
                  rel="noopener"
                >
                  <Circle>
                    <FaFacebookF {...cssFa} />
                  </Circle>
                </A>
                <A
                  href="https://www.instagram.com/docteurkron/"
                  title="Compte instagram du cabinet du Dr Cédric Kron"
                  target="_blank"
                  rel="noopener"
                >
                  <Circle>
                    <FaInstagram {...cssFa} />
                  </Circle>
                </A>
                <A
                  href="https://twitter.com/Dr_Kron"
                  title="Compte Twitter du Dr Cédric Kron, chirurgien esthetique"
                  target="_blank"
                  rel="noopener"
                >
                  <Circle>
                    <FaTwitter {...cssFa} />
                  </Circle>
                </A>
                <A
                  href="https://www.youtube.com/channel/UCCbRUwoTEbXraAeMVIN9DuA"
                  title="Chaine Youtube du Dr Cédric Kron"
                  target="_blank"
                  rel="noopener"
                >
                  <Circle>
                    <FaYoutube {...cssFa} />
                  </Circle>
                </A>
                <A
                  href="https://www.linkedin.com/in/kron-c%C3%A9dric-28492917/"
                  title="Profil Linkedin du Dr Cédric Kron"
                  target="_blank"
                  rel="noopener"
                  css={{
                    [mediaQueries.phone]: {
                      display: `none`,
                    },
                  }}
                >
                  <Circle>
                    <FaLinkedinIn {...cssFa} />
                  </Circle>
                </A>
              </Col>
              <Col span={{ xs: 6 / 12, lg: 21 / 100 }}>
                <H4>Interventions</H4>
                <Ul>
                  <Li>
                    <Link {...cssLink} to="/visage/">
                      Visage
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/regard/">
                      Regard
                    </Link>
                  </Li>

                  <Li>
                    <Link {...cssLink} to="/nez/">
                      Nez
                    </Link>
                  </Li>

                  <Li>
                    <Link {...cssLink} to="/oreilles/">
                      Oreilles
                    </Link>
                  </Li>

                  <Li>
                    <Link {...cssLink} to="/seins/">
                      Seins
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/silhouette/">
                      Silhouette
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/sexe/">
                      Sexe
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/jambes/">
                      Jambes
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/peau/">
                      Peau
                    </Link>
                  </Li>
                </Ul>
              </Col>
              <Col
                span={{ xs: 4 / 12, lg: 21 / 100 }}
                css={{
                  [mediaQueries.phone]: {
                    display: `none`,
                  },
                }}
              >
                <H4>Techniques</H4>
                <Ul>
                  <Li>
                    <Link {...cssLink} to="/acide-hyaluronique/">
                      Acide hyaluronique
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/botox/">
                      Botox
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/chirurgie-plastique/">
                      Chirurgie plastique
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/chirurgie-reparatrice/">
                      Chirurgie réparatrice
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/lifting/">
                      Lifting
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/lipoaspiration/">
                      Lipoaspiration
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/protheses/">
                      Prothèses
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/peeling/">
                      Peeling
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/suppression-des-graisses/">
                      Suppression des graisses
                    </Link>
                  </Li>
                </Ul>
              </Col>
              <Col span={{ xs: 6 / 12, lg: 21 / 100 }}>
                <H4>Le cabinet</H4>
                <Ul>
                  <Li>
                    <Link {...cssLink} to="/actualites/">
                      Actualités
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/avant-apres/">
                      Avant - Après
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/chirurgien-esthetique-paris/">
                      Chirurgien esthétique à Paris
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/centre-esthetique-paris/">
                      Centre esthétique
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/consultations/">
                      Consultations
                    </Link>
                  </Li>

                  <Li>
                    <Link {...cssLink} to="/equipe-cabinet/">
                      L'Équipe
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/revue-de-presse/">
                      Revue de presse
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/temoignages-avis-patients/">
                      Témoignages
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/video-chirurgie-esthetique/">
                      Vidéos
                    </Link>
                  </Li>
                </Ul>
              </Col>
            </Row>
            <Row>
              <Col>
                <Copyright
                  css={{
                    [mediaQueries.phone]: {
                      fontSize: `.7em`,
                      textAlign: `center`,
                      marginTop: `1.3em`,
                    },
                  }}
                >
                  &copy;2025 Dr Cédric Kron - 57 av. de Villiers - 75017
                  Paris&nbsp;-&nbsp;
                  <Link
                    {...cssLink}
                    to="/mentions-legales/"
                    css={{
                      [mediaQueries.phone]: {
                        display: `none`,
                      },
                    }}
                  >
                    Mentions légales
                  </Link>
                </Copyright>
              </Col>
            </Row>
          </Container>
        </Wrapper>
      </FooterDiv>
    );
  }
}
